import React from "react";
import { graphql } from "gatsby";

import { parseAsText } from "utils/parser";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";

import SwitchTheme from "components/SwitchTheme";
import Redactor from "components/Redactor";
import Grid from "components/Grid";
import GridItem from "components/GridItem";
import Image from "components/Image";

import {
  ImageSection,
  BoxSection,
  Button,
  Headline,
  Text,
  TextBottom,
  TextVisual,
  ImgFgContainer,
  ImageFg,
  ImageBg,
  TextColumnsTop,
  TopGrid,
  MiddleText,
  BottomText,
  GreenBox,
  DecorationLayer,
  Overlay,
  ColorFadeBottom,
  ColorFadeTop,
  ImgBgContainer,
  GridItemText3,
  GridItemText2,
  GridItemText1,
  Line1,
  Line2,
  LineFill,
  GridItemHeadline1,
} from "./MembershipSpecialStyled";

export default function MembershipSpecial({ pageBuilder }) {
  const {
    image,
    button,
    greenBox,
    decorationText,
    text1,
    text2,
    text3,
    headline1,
    headline2,
  } = pageBuilder;

  return (
    <SwitchTheme name="default">
      <ImageSection>
        <TextColumnsTop>
          <TopGrid>
            <GridItemHeadline1 laptop={"50%"} desktop={"43%"}>
              <Fade bottom distance="10px" delay={500}>
                <Headline element="h1">{headline1}</Headline>
              </Fade>
            </GridItemHeadline1>
            <GridItemText1 laptop={1 / 2}>
              <Text>
                <Fade bottom distance="10px" delay={1500}>
                  <Redactor content={text1?.content} parseType="content" />
                </Fade>
              </Text>
              <Line1>
                <Reveal effect="scale-x-left" delay={1000} duration={500}>
                  <LineFill />
                </Reveal>
              </Line1>
            </GridItemText1>
          </TopGrid>
        </TextColumnsTop>

        <ImgBgContainer>
          <ColorFadeTop />
          <Overlay />

          {image[1] && (
            <ImageBg>
              <Fade delay={1000} duration={2000}>
                <Image
                  fluid={image[1]}
                  aspectRatio={image[1].width / image[1].height}
                />
              </Fade>
            </ImageBg>
          )}

          <ColorFadeBottom />
        </ImgBgContainer>

        {image[0] && (
          <ImgFgContainer>
            <Fade bottom distance="30px">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  left: 0,
                  top: 0,
                  zIndex: 3,
                }}
              >
                <ImageFg src={image[0].url} />
              </div>
            </Fade>
          </ImgFgContainer>
        )}

        {decorationText && (
          <DecorationLayer>
            <Fade bottom distance="10px" delay={2500}>
              <TopGrid>
                <GridItem>
                  <TextVisual>{parseAsText(decorationText)}</TextVisual>
                </GridItem>
              </TopGrid>
            </Fade>
          </DecorationLayer>
        )}

        <MiddleText>
          <Grid>
            <GridItemText2 laptop={1 / 2}>
              <div className="div">
                <Fade bottom distance="10px" delay={2000}>
                  <Headline element="h1">{headline2}</Headline>
                </Fade>
                <Fade bottom distance="10px" delay={2200}>
                  <Text>
                    <Redactor content={text2?.content} parseType="content" />
                  </Text>
                </Fade>
              </div>
              <Line2>
                <Reveal effect="scale-x-right" delay={1500} duration={1000}>
                  <LineFill />
                </Reveal>
              </Line2>
            </GridItemText2>
          </Grid>
        </MiddleText>
      </ImageSection>

      <BoxSection>
        <BottomText>
          <Grid>
            <GridItemText3 laptop={1 / 2}>
              <Fade bottom distance="10px" delay={2000}>
                <TextBottom>
                  <Redactor content={text3?.content} parseType="content" />
                </TextBottom>
              </Fade>
            </GridItemText3>
            <GridItem laptop={1 / 2}>
              <Fade bottom distance="10px" delay={2200}>
                <GreenBox>
                  <Text>
                    <Redactor content={greenBox?.content} parseType="content" />
                  </Text>
                  {button.url && (
                    <Button
                      to={button?.entry?.fullUri || button?.url}
                      target={button.target}
                      icon="arrowRight"
                      themeName="default"
                      uppercase
                    >
                      {button.text}
                    </Button>
                  )}
                </GreenBox>
              </Fade>
            </GridItem>
          </Grid>
        </BottomText>
      </BoxSection>
    </SwitchTheme>
  );
}

export const query = graphql`
  fragment PageBuilderMembershipSpecialQuery on Craft_PageBuilderMembershipSpecial {
    image {
      ...CraftImageFluid
    }
    button {
      url
      entry {
        fullUri
      }
      customText
      defaultText
      target
      text
    }
    greenBox {
      content
    }
    decorationText
    text1 {
      content
    }
    text2 {
      content
    }
    text3 {
      content
    }
    image {
      url
    }
    headline1
    headline2
  }
`;
