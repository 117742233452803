import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Wrap from "components/Wrap";
import HeadlineComponent from "components/Headline";
import TextVisualComponent from "components/TextVisual";
import Grid from "components/Grid";
import GridItem from "components/GridItem";
import ButtonComponent from "components/Button";

export const Headline = styled(HeadlineComponent)({}, (props) => ({}));

export const LineFill = styled("div")((props) => ({
  background: props.theme.colorTertiary,
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
}));

export const Line1 = styled("div")((props) => ({
  height: 2,
  width: "10%",
  position: "absolute",
  top: 25,
  left: 0,
  [media(0, "tabletFluid")]: { display: "none" },
}));

export const Line2 = styled("div")((props) => ({
  height: 2,
  width: "70%",
  position: "absolute",
  top: 25,
  left: "79%",
  zIndex: 2,

  [media(0, "tabletFluid")]: { display: "none" },
}));

export const TextColumnsTop = styled("div")(
  {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 5,
    padding: "50px 0 0",

    [media("laptop")]: {
      marginBottom: -250,
      padding: "50px 100px 0",
      "> div": {
        width: "100%",
      },
    },

    [media("desktop")]: {
      paddingTop: 60,
      paddingBottom: 60,
      marginBottom: 0,
      zIndex: 5,
      position: "absolute",
      top: 0,
      width: "100%",
      maxWidth: 1532,
      left: "50%",
      transform: "translate(-50%)",
    },
  },
  (props) => ({})
);

export const TopGrid = styled(Grid)({});

export const TextBottom = styled("div")({
  p: { fontSize: 22, fontWeight: 700 },
});

export const Button = styled(ButtonComponent)({
  [media("laptop")]: { marginLeft: 55 },
});

export const Text = styled("div")((props) => ({
  ul: {
    textAlign: "left",
    margin: "30px auto",
    padding: "0 10px 0 20px",
    listStyle: "none",

    li: {
      position: "relative",
      paddingLeft: 30 + 5,
      "&:before": {
        content: "' '",
        display: "block",
        position: "absolute",
        left: 0,
        top: 11,
        height: `20px`,
        width: `30px`,
        transform: "translate(-50%,-50%)",
        background: `url(https://cms.evofitness.de/images/haken.svg) no-repeat center center`,
        backgroundSize: "contain",
      },
    },
  },

  li: {
    marginBottom: 20,
  },
}));

export const GridItemHeadline1 = styled(GridItem)({
  [media(0, "laptop")]: {
    h1: {
      fontSize: 35,
    },
  },
  [media("laptop")]: {
    textAlign: "right",
  },
});

export const GridItemText1 = styled(GridItem)({
  [media("laptop")]: {
    paddingLeft: "7%",
    position: "relative",
    p: { marginTop: 10 },
  },
  zIndex: 3,
});

export const GridItemText2 = styled(GridItem)({
  position: "relative",

  "> .div": {
    position: "relative",
    zIndex: 4,
  },

  [media("desktop")]: {
    paddingLeft: 150,
  },
});

export const MiddleText = styled("div")(
  {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  (props) => ({})
);

export const ImageSection = styled(Wrap)({
  background: "#101319",
  position: "relative",
  zIndex: 0,
  paddingTop: 500,
  paddingBottom: 200,
  overflow: "hidden",
  [media("tablet")]: {
    paddingTop: 300,
  },
  [media("tabletFluid")]: {
    paddingTop: 200,
    paddingBottom: 0,
  },
  [media("desktop")]: {
    paddingBottom: 0,
  },
});

export const ImgBgContainer = styled("div")({ position: "relative" });

export const ImageBg = styled("div")(
  {
    position: "relative",
    zIndex: 0,
    pointerEvents: "none",
  },
  (props) => ({})
);

export const ImgFgContainer = styled("div")({
  [media(0, "tabletFulid")]: { display: "none" },
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  position: "absolute",
  zIndex: 3,
});

export const ImageFg = styled("img")(
  {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    objectFit: "contain",
    zIndex: 3,

    paddingTop: 500,
    paddingBottom: 200,
    [media("tablet")]: {
      paddingTop: 300,
    },
    [media("tabletFluid")]: {
      paddingTop: 200,
      paddingBottom: 0,
    },
    [media("desktop")]: {
      paddingBottom: 0,
    },
  },
  (props) => ({})
);

export const Overlay = styled("div")(
  {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(20,20,20,0.55)",
    zIndex: 1,
    display: "none",
    [media("desktop")]: { display: "block" },
  },
  (props) => ({})
);
export const ColorFadeBottom = styled("span")(
  {
    display: "block",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 100,
    background: "rgb(16,19,25)",
    background:
      "linear-gradient(180deg, rgba(16,19,25,0) 0%, rgba(16,19,25,1) 100%)",
    zIndex: 4,
    [media("tablet")]: { height: 174 },
  },
  (props) => ({})
);

export const ColorFadeTop = styled("span")(
  {
    display: "block",
    position: "absolute",
    top: 0,
    width: "100%",
    height: 100,
    background: "rgb(16,19,25)",
    background:
      "linear-gradient(0deg, rgba(16,19,25,0) 0%, rgba(16,19,25,1) 100%)",
    zIndex: 4,
    [media("tablet")]: { height: 174 },
  },
  (props) => ({})
);

export const DecorationLayer = styled("div")({
  [media("laptop")]: {
    marginTop: "23vw",
    zIndex: 1,
    position: "absolute",
    top: 0,
    width: "100%",
    maxWidth: 1532,
  },
});

export const TextVisual = styled(TextVisualComponent)(
  {
    opacity: 0.35,
  },
  (props) => ({
    fontSize: "3vw",

    [media("laptop")]: {
      fontSize: "7vw",
    },
  })
);

export const BoxSection = styled(Wrap)({
  background: "#101319",
  zIndex: 0,
});

export const GridItemText3 = styled(GridItem)({
  [media("desktop")]: {
    paddingLeft: 150,
    paddingTop: 30,
  },
});

export const BottomText = styled("div")(
  { background: "#101319", padding: "50px 0 50px" },
  (props) => ({})
);

export const GreenBox = styled("div")(
  { background: "#074D57", padding: "30px 30px" },
  (props) => ({})
);
